<template>
	<Layout>
		<template>
			<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(updateProfile)">
					<article class="profile__column panel">
						<div class="panel__header">
							<span class="is-flex is-flex-direction-column is-justify-content-center">
								<h3 class="profile__name pt-0 is-medium is-size-4">Perfil</h3>
								<p class="is-size-6">Actualiza tus datos personales</p>
							</span>
							<b-button v-if="isDesktop" tabindex="6" native-type="submit" type="is-primary save" :loading="loading" rounded>Guardar</b-button>
						</div>
						<div class="panel__body">
							<h3 class="profile__section mb-5 has-text-primary is-medium is-size-5">Datos Personales</h3>
							<InputWithValidation class="profile__field" tab="1" rules="required" type="text" label="Nombre" size="is-medium" v-model="user.first_name" translate="no"/>

							<InputWithValidation class="profile__field" tab="2" rules="required" type="text" label="Apellido" size="is-medium" v-model="user.last_name" />

							<InputWithValidation class="profile__field" tab="3" rules="required|email" type="email" label="Correo Electrónico" size="is-medium" v-model="user.email" />

							<div class="is-flex is-justify-content-flex-end mb-3">
								<b-button v-if="!isDesktop" tabindex="6" native-type="submit" type="is-primary save" :loading="loading" rounded>Guardar</b-button>
							</div>
						</div>
					</article>
				</form>
			</ValidationObserver>
		</template>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Profile'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import { mapState } from 'vuex'
import { toast } from '@/mixins/toast'
import { responsive } from '@/mixins/responsive'

export default {
	name: 'Personal',
	mixins: [responsive],
	components: {
		Layout,
		InputWithValidation,
		ValidationObserver
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		async updateProfile() {
			try {
				this.loading = true
				if (this.user.site) {
					const url = this.user.site.replace('http://', '').replace('https://', '')
					this.user = { ...this.user, site: `http://${url}` }
				}
				const response = await Api.put(`user/personal`, this.user)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					toast('is-success', '¡Tu perfil ha sido <strong>actualizado</strong>!')
				}
			} catch (e) {
				const { status } = e
				if (status === 500) {
					toast('is-danger', '¡Ooops, ya hay otro usuario con ese correo electrónico!')
				}
			} finally {
				this.loading = false
			}
		}
	},
	computed: {
		...mapState('user', ['user']),
	}
}
</script>
